<template>
    <div class="blackout" v-if="showPopup" @click="$emit('closePopup')">
        <div class="popup" @click.stop>
            <div class="close" @click="$emit('closePopup')"></div>
            <h1 v-if="'owner_registration_form' != showPopup">{{ getTitle() }}</h1>
            <!--<p>{{ getText() }}</p>-->
            <div class="popup-text" v-if="showPopup.type == 'privacy'">
                <p>{{ $t('common_privacy_text') }}</p>
            </div>
            <div class="popup-text" v-if="showPopup == 'owner_registration_form'">
                <form ref="ownerRegForm">
                    <span class="no-select text-size1">
                        {{ $t('owner_registration_form_title') }}
                    </span>
                    <div class="center-wrapper">
                        <input ref="ownerRegFormMail" class="text-input" type="text" :value="ownerMail" @input="setOwnerMail" placeholder="ana@example.com">
                        <button @click="register()" class="btn width100" type="button">
                            {{ $t('owner_registration_form_verify_button') }}
                        </button>
                    </div>
                </form>
                <form class="hide" ref="ownerRegFormAnswer">
                    <span class="no-select text-size2">
                        {{ $t('owner_registration_form_text') }}
                    </span>
                    <br><br>
                    <div class="center-wrapper">
                        <button @click="$emit('closePopup')" class="btn width100" type="button">
                            {{ $t('owner_registration_form_ok_button') }}
                        </button>
                    </div>
                </form>
            </div>

            <div v-else class="popup-text">
                <span class="underline">Versión vigente desde 10.2021</span>
                <p>Este contrato describe los términos y condiciones generales (los “Términos y Condiciones Generales”) aplicables al uso de del servicio Epark.online, dentro de los sitios <a href="https://epark.online/">www.epark.online</a> (“Epark.online” o el “sitio”), y/o paginas web asociadas y la aplicación móvil descargada de cualquier store de aplicaciones (“la App”). Epark.online es propiedad de Alexandra Petrachkova, CUIT 27-19016814-5. Cualquier persona (en adelante “Usuario” o en plural “Usuarios”) que desee acceder y/o usar el sitio o los Servicios podrá hacerlo sujetándose a los Términos y Condiciones Generales respectivos, junto con todas las demás políticas y principios que rigen y que son incorporados al presente por referencia.</p>
                <strong>CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS.</strong>
                <p>El Usuario debe leer, comprender y aceptar todas las condiciones establecidas en los Términos y Condiciones Generales, así como en los demás documentos incorporados a los mismos por referencia, previas a su registración como Usuario de Epark.online, o antes de utilizar el servicio Epark.online, optando de forma expresa por recibir los mismos y toda otra información por medios digitales.</p>
                <strong>I. Capacidad</strong>
                <p>Los Servicios sólo van a ser utilizables para personas que tengan capacidad legal para contratar. No serán habilitados los servicios las personas que no tengan esa posibilidad, los menores de edad o Usuarios de Epark.online que hayan sido suspendidos temporalmente o inhabilitados definitivamente. Si estás registrando un Usuario como Empresa, debes tener la capacidad para contratar a nombre de tal entidad y de comprometerse a la misma en los términos de este Acuerdo.</p>
                <strong>II. Registración</strong>
                <p>Es obligatorio completar el formulario de registración en todos sus campos con datos legítimos para poder utilizar los servicios que brinda Epark.online. El futuro Usuario deberá completarlo con su información personal de manera exacta, precisa y valida (“Datos Personales”) y asume el acuerdo de actualizar los Datos Personales si esto resulta necesario. Epark.online podrá utilizar diversas formas para identificar a sus Usuarios, pero Epark.online no se responsabiliza por la validez de los Datos Personales provistos por sus Usuarios. Los Usuarios aseguran y responden, de todos modos, de la veracidad, exactitud, vigencia y autenticidad de los Datos Personales ingresados.</p>
                <p>El Usuario ingresará a su cuenta personal (“Cuenta”), desde la App, utilizando su nombre de usuario y clave personal escogida (“Clave de Seguridad”). El Usuario se obliga a mantener la confidencialidad de su Clave de Seguridad.</p>
                <p>La Cuenta es personal, única e intransferible, y no está permitido que un mismo Usuario registre o posea más de una Cuenta.</p>
                <p>El Usuario será responsable de la totalidad de las operaciones efectuadas en su Cuenta, pues el acceso a la misma está restringido al ingreso y uso de su Clave de Seguridad, de conocimiento exclusivo del Usuario. El Usuario se compromete a notificar a Epark.online en forma inmediata y por medio idóneo y fehaciente, de cualquier uso no autorizado de su Cuenta, así como el ingreso por terceros no autorizados a la misma. Se aclara que está prohibida la venta, cesión o transferencia de la Cuenta bajo ninguna forma.</p>
                <p>El Usuario se compromete a suministrar y mantener actualizados a Epark.online los siguientes datos:</p>
                <ul>
                    <li>Nombre</li>
                    <li>Apellido</li>
                    <li>DNI</li>
                    <li>Mail</li>
                </ul>
                <p>Epark.online tiene reservado el derecho de rechazar cualquier solicitud de registración o de cancelar una registración previamente aceptada, sin que esté obligado a comunicar o exponer las razones de su resolución y sin que ello genere algún derecho a indemnización o resarcimiento.</p>
                <strong>III. Modificaciones del Acuerdo</strong>
                <p>Epark.online podrá cambiar los Términos y Condiciones Generales en todo momento haciendo públicos en el Sitio los términos modificados. Todos los términos modificados entrarán en vigencia a los 10 (diez) días desde su comunicado. Dichas modificaciones serán anunciadas por Epark.online a los usuarios que en la Configuración de su Cuenta de Epark.online hayan indicado que desean recibir notificaciones de los cambios en estos Términos y Condiciones. Todo usuario que no esté de acuerdo con los cambios efectuados por Epark.online podrá solicitar la baja de la cuenta.</p>
                <p>El uso del sitio, y/o la App, y/o sus servicios implica la aceptación de estos Términos y Condiciones generales de uso de Epark.online.</p>
                <strong>IV. El Servicio</strong>
                <p>IV.1 Servicio</p>
                <p>IV.1.1 Objeto</p>
                <p>El presente es un servicio dado sólo a los Usuarios registrados en el Sitio o en la App Epark.online, por medio del cual Epark.online se obliga a conservar un espacio disponible de estacionamiento en las locaciones propias o de terceros en donde Epark.online posea un acuerdo vigente.</p>
                <p>El servicio de estacionamiento será fraccionado de acuerdo al siguiente detalle:</p>
                <p>a) Estadía por hora<br/>
                b) Media estadía<br/>
                c) Estadía diaria</p>
                <p>IV.1.2 Obligaciones de Epark.online.</p>
                <p>Epark.online se compromete a gestionar y confirmar por medio de la App, y sus versiones actualizadas, al Usuario registrado, la disponibilidad y reserva de espacio desocupado de estacionamiento en las locaciones de terceros, en donde Epark.online posea un acuerdo vigente.</p>
                <p>La solicitud de reserva no implica la obligación de esta, la que se gestará solo con la confirmación por parte de Epark.online.</p>
                <p>IV.1.3 Obligaciones del Usuario.</p>
                <p>El Usuario se compromete a pagar el precio de acordado al fraccionamiento detallado en el punto IV.1.1 de los presentes Términos y Condiciones. Asimismo, se compromete a realizar la reserva, con todos los datos pedidos por la App.</p>
                <p>IV.1.4 Precio</p>
                <p>El precio será establecido por Epark.online, y el mismo podrá ser preguntado por el Usuario, con anterioridad de la solicitud de la reserva para un lugar disponible.</p>
                <p>El cargo se generará al momento de la confirmación, el que se facturará independientemente de la utilización del espacio reservado por parte del Usuario registrado. El Usuario recibe 15 minutos de estacionamiento gratis, es decir puede llegar 15 minutos antes del comienzo de su reserva o salir 15 minutos después del fin de su reserva o usar este beneficio en cualquier combinación antes y después del tiempo reservado sin pagar la fracción. Si su estadía supera el tiempo reservado más 15 minutos del beneficio debe pagar directamente al estacionamineto según su tarifa actual.</p>
                <strong>V. Privacidad de la Información</strong>
                <p>Para usar los Servicios ofrecidos por Epark.online, los Usuarios deberán dar acceso a datos personales. Su información personal se procesa y almacena en servidores o medios magnéticos de alta seguridad y protección tanto física como tecnológica.</p>
                <strong>VI. Violaciones del Sistema o Bases de Datos</strong>
                <p>No está permitida ninguna acción o uso de dispositivo, hardware, software, u otro medio tendiente a interferir tanto en las actividades y operatoria de Epark.online como en la información personal o base de datos de Epark.online. Cualquier interrupción, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en este contrato harán pasible a su responsable de las acciones legales pertinentes, y a las sanciones detalladas en este acuerdo, así como lo hará responsable de indemnizar los daños ocasionados.</p>
                <strong>VII. Sanciones. Suspensión de operaciones</strong>
                <p>Sin perjuicio de otras medidas, Epark.online podrá, suspender en forma temporal o definitivamente la Cuenta de un Usuario, iniciar las acciones que estime pertinentes y/o suspender la prestación de sus Servicios si (i) si es contra una ley o cualquiera de las estipulaciones de los Términos y Condiciones Generales y otras políticas de Epark.online; (ii) si no se respetan los compromisos como Usuario; (iii) si por criterio del equipo Epark.online hay acciones fraudulentas; (iv) la inhabilidad de verificar los datos personales del Usuario o cualquier información proporcionada por el mismo fuere errónea.</p>
                <strong>VIII. Responsabilidad</strong>
                <p>Epark.online sólo pone a disposición de los Usuarios información sobre lugares para estacionar disponibles de terceros con los que Epark.online tiene un acuerdo en el momento actual, y a reservar dicho lugar mediante la confirmación del Usuario registrado. Epark.online no responde por el deterioro que pueda ser ocasionados al ingreso, durante la estadía, o al momento del egreso del espacio de estacionamiento confirmado por Epark.online.</p>
                <strong>IX. Alcance de los servicios de Epark.online</strong>
                <p>Este acuerdo no crea ningún contrato de sociedad, de mandato, de franquicia, o relación laboral entre Epark.online y el Usuario. El usuario reconoce y acepta que Epark.online no es parte en ninguna operación, ni tiene control alguno sobre la calidad, seguridad o legalidad de los espacios disponibles o de los servicios de estacionamiento que brindan los terceros con los que Epark.online tiene convenio.</p>
                <strong>X. Fallas en el sistema</strong>
                <p>Epark.online no se responsabiliza por cualquier deterioro, perjuicio o pérdida al usuario causados por defecto en el sistema, el servidor o en Internet. Epark.online tampoco será responsable por cualquier virus que pueda acceder al equipo del usuario como consecuencia del acceso, uso o examen de su sitio web o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio que contenga estos. Los usuarios no podrán imputarle responsabilidad alguna ni exigir compensación por lucro cesante, en virtud de perjuicios resultantes de dificultades tecnológicas o fallas en los sistemas o en Internet. Epark.online no garantiza el acceso y uso continuado o ininterrumpido de su sitio o aplicaciones. El sistema puede eventualmente no estar disponible debido a dificultades tecnológicas o fallas de Internet, o por cualquier otra circunstancia ajena a Epark.online; en tales casos se procurará restablecerlo lo antes posible sin que por ello pueda imputársele algún tipo de responsabilidad. Epark.online no será responsable por ninguna falla u omisión contenidos en su sitio web.</p>
                <strong>XI. Tarifas. Facturación</strong>
                <p>La registración en el Sitio o la descarga de la App de Epark.online es gratis. El usuario solo deberá pagar a Epark.online en caso de que esta le afirme la reserva del lugar de estacionamiento solicitado.</p>
                <p>Epark.online se reserva el derecho de tomar las medidas judiciales y extrajudiciales que estime pertinentes para obtener el pago del monto debido. </p>
                <p>Epark.online se reserva el derecho de modificar, cambiar, agregar, o eliminar las tarifas vigentes, en cualquier momento, lo cual será notificado a los Usuarios, en la forma establecida en la Cláusula 3. Sin embargo, Epark.online podrá modificar temporalmente la Política de Tarifas y las tarifas por sus servicios por razón de promociones, siendo efectivas estas modificaciones cuando se haga pública la promoción o se realice el anuncio.</p>
                <p>En caso de haberse facturado cargos que no hubiesen correspondido, el usuario deberá comunicarse con nuestro equipo de Atención al Cliente para resolver dicha cuestión.</p>
                <strong>XII. Reintegros.</strong>
                <p>El usuario solo tendrá derecho de reintegro del 100% de lo efectivamente abonado a Epark.online o a los terceros con los que Epark.online tiene convenio en concepto de espacio de estacionamiento reservado, en los casos en los que sin perjuicio de haber recibido la confirmación de la reserva del espacio de estacionamiento disponible, al momento de ingresar al mismo, el espacio no se encuentra disponible.</p>
                <p>Los reintegros se realizaran por el mismo medio de pago utilizado al momento de hacer la reserva y se procesaran en el término de 30 días hábiles.</p>
                <p>El reintegro también procederá en los supuestos de que el Evento al que se encuentre asociada la reserva del espacio de estacionamiento disponible se cancele, en este supuesto el mismo se realizará a través del mismo medio que la devolución o reintegro del concepto del Evento.</p>
                <strong>XIII. Propiedad intelectual. Enlaces</strong>
                <p>Los contenidos de las pantallas relativas a los servicios de Epark.online como así también los programas, bases de datos, redes, archivos que permiten al Usuario acceder y usar su Cuenta, u otra información de aquel estilo son de propiedad de Epark.online y están protegidas por las legislaciones y los tratados internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción completa o parcial de dichos contenidos e información quedan prohibidos, salvo autorización expresa y por escrito de Epark.online.</p>
                <strong>XIV. Indemnización</strong>
                <p>El usuario acepta indemnizar y eximir de responsabilidad a Epark.online (incluyendo pero no limitando a sus sociedades relacionadas, sus respectivos directores, gerentes, funcionarios, representantes, agentes y empleados) por cualquier pedido, reclamo o demanda (incluidos los honorarios razonables de abogados) formulados contra los terceros propietarios de espacios de estacionamiento con los que Epark.online tenga algún convenio en aquel momento.</p>
                <p>Ambas Partes quedarán eximidas de responsabilidad en el caso que se produzcan daños y perjuicios en supuestos de fuerza mayor tales como: catástrofes naturales, inundaciones, huelgas, falta total o parcial de energía, así como cualquier otra circunstancia cuyo control no dependa de las responsabilidades, conductas y obligaciones asumidas por las Partes en los presentes términos y condiciones.</p>
                <p>Epark.online, en ningún caso responderá frente al Usuario por daños y perjuicios causados por las empresas con las que haya un contrato en ese momento, o terceros no vinculados, sus empleados o dependientes, antes, durante o al finalizar el uso del lugar de estacionamiento reservado.</p>
                <strong>XV. Datos Personales</strong>
                <p>El autorizo autoriza a Epark.online a enviarme propuestas comerciales relacionadas a sus servicios y/o productos, realizar análisis de mercado, hacer uso de mis datos con fines de atención al cliente. Como titular de los datos personales el usuario podrá ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, en virtud de lo establecido por la Disposición Nro. 4 de la Dirección Nacional de Protección de Datos Personales y de los arts. 27, inc. 3 de la ley 25.326 y 27, párrafo 3ro. del Decreto 1558/01, referidos a las bases de datos con fines de publicidad. El usuario tiene derecho al retiro o bloqueo, total o parcial, de su nombre de esta base de datos, lo que podrá hacer enviando su decisión en tal sentido al correo electrónico info@epark.online. La Dirección Nacional de Protección de Datos Personales, órgano de control de la ley Nro. 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan en relación a incumplimiento de las normas sobre Protección de Datos Personales.</p>
                <strong>XVI. Jurisdicción</strong>
                <p>Los Usuarios aceptan someterse a toda cuestión que se le pida con motivo del servicio a los Tribunales Ordinarios de la Ciudad Autónoma de Buenos Aires o de los tribunales competentes debido a la materia, en cuyo caso la competencia territorial resultará la del fuero correspondiente al domicilio del usuario.</p>
                <strong>XVII. Eliminación de datos</strong>
                <p>El usuario tiene derecho al retiro o bloqueo, total o parcial, de su nombre y sus datos personales de esta base de datos, lo que podrá hacer enviando su decisión en tal sentido al correo electrónico info@epark.online. Epark.online revisará el pedido y eliminará la información personal dentro de los 10 días habiles.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { useVuelidate } from 'vuelidate'
    import { email, required, maxLength } from 'vuelidate/lib/validators';

    export default {
        props: ['showPopup'],
        setup (){
            return { v$: useVuelidate() };
        },
        data  () {
            return {
                ownerMail: ''
            }
        },
        validations () {
            return {
                ownerMail: {
                    required,
                    email
                }
            }
        },

        methods: {
            getTitle () {
                if (this.showPopup.type == 'privacy') {
                    return this.$t('common_privacy_title')
                } else {
                    return this.$t('common_terminos_title')
                }
            },
            getText () {
                if (this.showPopup.type == 'privacy') {
                    return this.$t('common_privacy_text')
                } else {
                    return this.$t('common_terminos_text')
                }
            },
            setOwnerMail ($event){
                // this.ownerMail = $event.target.value.toUpperCase()
                // this.v$.ownerMail.$touch()
            },
            register() {
                // const result = await this.v$.validate()
                var data = {
                    mail: (this.$refs.ownerRegFormMail.value).toString()
                }
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                console.log(data.mail)
                if('' != data.mail && re.test(data.mail)){
                    this.$refs.ownerRegForm.className = 'hide';
                    this.$refs.ownerRegFormAnswer.className = '';
                }else{
                    var classValue = this.$refs.ownerRegFormMail.className
                    if(!classValue.includes('error')){
                        this.$refs.ownerRegFormMail.className += ' error';
                    }else{
                        this.$refs.ownerRegFormMail.className = classValue.replaceAll('error', '');
                        setTimeout(() => {this.$refs.ownerRegFormMail.className += ' error';}, 200);
                    }
                }
            }
        }
    }
</script>