<template>
  <section id="for_parkingmans">
    <div class="table-col2 owner-adv-banner" :class="{mobile: $isMobile()}">
      <div>
        <div>
          <h1 v-html="$t('owner_adv_banner1_title')"></h1>
          <p v-html="$t('owner_adv_banner1_text')"></p>
        </div>
        <div>
          <img v-if="!$isMobile()" src="~@/assets/images/banners/owner-adv.png"/>
          <img v-else src="~@/assets/images/banners/owner-adv2.png"/>
          <h1 v-html="$t('owner_adv_banner1_title')"></h1>
          <p v-html="$t('owner_adv_banner1_text')"></p>
        </div>
      </div>
    </div>
    <div id="info" :class="{mobile: $isMobile()}">
      <!-- Link tag -->
    </div>
    <div class="wrapper questions-block" :class="{mobile: $isMobile()}">
      <div class="table-col2-mob1">
        <div class="highlight-row">
          <div>
            <h2 v-html="$t('owner_question1_title')"></h2>
            <p v-html="$t('owner_question1_text1')"></p>
          </div>
          <div>
            <h2 v-html="$t('owner_question1_title')" class="empty-header"></h2>
            <p v-html="$t('owner_question1_text2')"></p>
            <img v-bind:alt="$t('usage_example_alt_text')" src="~@/assets/images/banners/parking-outside.png"/>
          </div>
        </div>
        <div>
          <div>
            <h2 v-html="$t('owner_question2_title')"></h2>
            <p v-html="$t('owner_question2_text')"></p>
            <div class="visual-table">
              <div>
                <div v-html="$t('owner_question2_table1_label1')"></div>
                <div v-html="$t('owner_question2_table1_label2')"></div>
                <div v-html="$t('owner_question2_table1_label3')"></div>
                <div v-html="$t('owner_question2_table1_label4')"></div>
                <div v-html="$t('owner_question2_table1_label5')"></div>
              </div>
              <div>
                <div>23.10.2023</div>
                <div>XX123YY</div>
                <div>11-00</div>
                <div>12-30</div>
                <div>3000</div>
              </div>
              <div>
                <div>18.10.2023</div>
                <div>XX345YY</div>
                <div>09-14</div>
                <div>10-14</div>
                <div>3160</div>
              </div>
              <div>
                <div>02.01.2024</div>
                <div>XX567YY</div>
                <div>14-22</div>
                <div>17-11</div>
                <div>5200</div>
              </div>
            </div>
          </div>
          <div>
            <h2 v-html="$t('owner_question3_title')"></h2>
            <p v-html="$t('owner_question3_text')"></p>
            <img v-bind:alt="$t('usage_example_alt_text')" src="~@/assets/images/banners/usage-example.png"/>
          </div>
        </div>
        <div class="highlight-row">
          <div>
            <h2 v-html="$t('owner_question4_title')"></h2>
            <p v-html="$t('owner_question4_text1')"></p>
          </div>
          <div>
            <h2 class="empty-header" v-html="$t('owner_question4_title')"></h2>
            <p v-html="$t('owner_question4_text2')"></p>
          </div>
        </div>
      </div>
      <div class="summarize qr">
        <h2 v-html="$t('owner_question5_title')"></h2>
        <p v-html="$t('owner_question5_text1')"></p>
      </div>
      <div class="center-wrapper">
        <button @click="openWhatsApp()" class="btn">{{ $t('start_selling_button') }}</button>
      </div>
      <div class="summarize">
        <p v-html="$t('owner_question5_text2')"></p>
      </div>
    </div>
    <div class="wrapper" :class="{mobile: $isMobile()}">
      <div class="separator"></div>
      <h1 class="title special">{{ $t('owner_title_5') }}</h1>
      <div class="text-video inline mob-col">
        <div class="center-wrapper">
          <Youtube video_id="SF81glzFMMU"></Youtube>
        </div>
      </div>
    </div>
    <DownloadLine></DownloadLine>
    <div class="wrapper" :class="{mobile: $isMobile()}">
        <h2 class="subtitle">{{ $t('owner_title_4') }}</h2>

        <div class="text-video inline _second mob-col">
            <p class="subtext left-col" v-html="$t('owner_leftText_3')"></p>
            <div class="right-col">
                <Youtube video_id="Q8Xe1xAhzes"></Youtube>
                <div class="separator"></div>
                <div class="faq">
                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 2,
                        title: $t('owner_faq1_title'),
                        content: {
                            items: [
                                {text: $t('owner_faq1_text1'), img: 'faq/parkingman/reg.png'},
                                {text: $t('owner_faq1_text2'), img: 'faq/parkingman/mas_tarde.png'},
                                {text: $t('owner_faq1_text3'), img: 'faq/parkingman/buscar_estacionamientos.png'},
                                {text: $t('owner_faq1_text4'), img: 'faq/parkingman/find_parking.png'},
                                {text: $t('owner_faq1_text5'), img: 'faq/parkingman/soy_duento.png'},
                                {text: $t('owner_faq1_text6'), img: 'faq/parkingman/foto_doko.png'},
                                {text: $t('owner_faq1_text7'), img: 'faq/parkingman/promocode.png'}
                            ]
                        }
                    }"></FaqItem>

                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 1,
                        title: $t('owner_faq2_title'),
                        content: {
                            items: [
                                {text: $t('owner_faq2_text1'), img: 'faq/parkingman/agregar.png'},
                                {text: $t('owner_faq2_text2'), img: 'faq/parkingman/checkout.png'},
                                {text: $t('owner_faq2_text3'), img: 'faq/parkingman/drag.png'},
                                {text: $t('owner_faq2_text4'), img: 'faq/parkingman/photo_enter.png'},
                                {text: $t('owner_faq2_text5'), img: 'faq/parkingman/photo_price.png'},
                                {text: $t('owner_faq2_text6'), img: 'faq/parkingman/shedule.png'},
                                {text: $t('owner_faq2_text7'), img: 'faq/parkingman/phone.png'},
                                {text: $t('owner_faq2_text8'), img: 'faq/parkingman/autorizar.png'},
                                {text: $t('owner_faq2_text9'), img: 'faq/parkingman/photo_doc.png'}
                            ]
                        }
                    }"></FaqItem>

                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 3,
                        title: $t('owner_faq3_title'),
                        content: {
                            items: [
                                {text: $t('owner_faq3_text1'), img: 'faq/parkingman/agregar.png'},
                                {text: $t('owner_faq3_text2'), img: 'faq/parkingman/las-reservas2.png'},
                                {text: $t('owner_faq3_text3'), img: 'faq/parkingman/las-reservas3.png'},
                                {text: $t('owner_faq3_text4'), img: 'faq/parkingman/las-reservas4.png'},
                                {text: $t('owner_faq3_text5'), img: 'faq/parkingman/las-reservas5.png'},
                                {text: $t('owner_faq3_text6'), img: 'faq/parkingman/las-reservas6.png'},
                                {text: $t('owner_faq3_text7'), img: 'faq/parkingman/las-reservas7.png'}
                            ]
                        }
                    }"></FaqItem>
                </div>
            </div>
        </div>
        <div class="separator"></div>
        <div class="text-video inline _second mob-col">
            <p class="subtext left-col" v-html="$t('owner_leftText_4')"></p>
            <div class="right-col">
                <Youtube video_id="BTKwEHVcGRM"></Youtube>
                <div class="separator"></div>
                <div class="faq">
                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 4,
                        title: $t('owner_faq4_title'),
                        content: {
                            items: [
                                {text: $t('owner_faq4_text1'), img: 'faq/parkingman/control1.png'},
                                {text: $t('owner_faq4_text2'), img: 'faq/parkingman/control2.png'},
                                {text: $t('owner_faq4_text3'), img: 'faq/parkingman/control3.png'}
                            ]
                        }
                    }"></FaqItem>

                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 5,
                        title: $t('owner_faq5_title'),
                        content: {
                            items: [
                                {text: $t('owner_faq5_text1'), img: 'faq/parkingman/el_menu.jpg'}
                            ]
                        }
                    }"></FaqItem>
                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 6,
                        title: $t('owner_faq6_title'),
                        content: {
                            text: '<p>' + $t('owner_faq6_text') + '</p>',
                            items: [
                                {img: 'faq/parkingman/el_menu1.png'},
                                {img: 'faq/parkingman/4.png'}
                            ]
                        }
                    }"></FaqItem>

                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 7,
                        title: $t('owner_faq7_title'),
                        content: {
                            text: '<p>' + $t('owner_faq7_text') + '</p>',
                            items: [
                                {img: 'faq/parkingman/el_menu2.png'},
                                {img: 'faq/parkingman/5.png'}
                            ]
                        }
                    }"></FaqItem>

                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 8,
                        title: $t('owner_faq8_title'),
                        content: {
                            text: '<p>' + $t('owner_faq8_text') + '</p>',
                            items: [
                                {img: 'faq/parkingman/el_menu3.png'},
                                {img: 'faq/parkingman/6.png'}
                            ]
                        }
                    }"></FaqItem>



                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 9,
                        title: $t('owner_faq9_title'),
                        content: {
                            text: '<p>' + $t('owner_faq9_text') + '</p>',
                            items: [
                                {img: 'faq/parkingman/el_menu4.png'},
                                {img: 'faq/parkingman/CONTACTOS.png'}
                            ]
                        }
                    }"></FaqItem>

                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 10,
                        title: $t('owner_faq10_title'),
                        content: {
                            items: [
                                {text: $t('owner_faq10_text1'), img: 'faq/parkingman/el_menu5.png'},
                                {text: $t('owner_faq10_text2'), img: 'faq/parkingman/datos1.png'},
                                {text: $t('owner_faq10_text3'), img: 'faq/parkingman/datos2.png'},
                            ]
                        }
                    }"></FaqItem>

                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 11,
                        title: $t('owner_faq11_title'),
                        content: {
                            text: '<p>' + $t('owner_faq11_text') + '</p>',
                            items: [
                                {img: 'faq/parkingman/el_menu6.png'},
                                {img: 'faq/parkingman/HORARIO.png'}
                            ]
                        }
                    }"></FaqItem>

                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 12,
                        title: $t('owner_faq12_title'),
                        content: {
                            text: '<p>' + $t('owner_faq12_text') + '</p>',
                            items: [
                                {img: 'faq/parkingman/el_menu7.png'},
                                {img: 'faq/parkingman/personas1.png'},
                                {img: 'faq/parkingman/personas2.png'}
                            ]
                        }
                    }"></FaqItem>

                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 13,
                        title: $t('owner_faq13_title'),
                        content: {
                            items: [
                                {text: $t('owner_faq13_text1'), img: 'faq/parkingman/bottom_menu.png'}
                            ]
                        }
                    }"></FaqItem>

                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 16,
                        title: $t('owner_faq14_title'),
                        content: {
                            items: [
                                {text: $t('owner_faq14_text1'), img: 'faq/parkingman/DISPONIBILIDAD.png'}
                            ]
                        }
                    }"></FaqItem>

                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 15,
                        title: $t('owner_faq15_title'),
                        content: {
                            items: [
                                {text: $t('owner_faq15_text1'), img: 'faq/parkingman/DESCUENTOS_TEMPORARIOS.png'}
                            ]
                        }
                    }"></FaqItem>

                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 14,
                        title: $t('owner_faq16_title'),
                        content: {
                            text: '<p>' + $t('owner_faq16_text') + '</p>',
                            items: [
                                {img: 'faq/parkingman/RESERVAS1.png'},
                                {img: 'faq/parkingman/RESERVAS2.png'},
                                {img: 'faq/parkingman/RESERVAS3.png'}
                            ]
                        }
                    }"></FaqItem>



                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 17,
                        title: $t('owner_faq17_title'),
                        content: {
                            text: '<p>' + $t('owner_faq17_text') + '</p>',
                            items: [
                                {text: $t('owner_faq17_text1'), img: 'faq/parkingman/check_in-out1.png'},
                                {text: $t('owner_faq17_text4'), img: 'faq/parkingman/check_in-out4.png'},
                                {text: $t('owner_faq17_text2'), img: 'faq/parkingman/check_in-out2.png'},
                                {text: $t('owner_faq17_text5'), img: 'faq/parkingman/check_in-out5.png'},
                                {text: $t('owner_faq17_text3'), img: 'faq/parkingman/check_in-out3.png'},
                            ]
                        }
                    }"></FaqItem>

                    <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                        id: 19,
                        title: $t('owner_faq18_title'),
                        content: {
                            items: [
                                {text: $t('owner_faq18_text1'), img: 'faq/parkingman/control3.png'},
                            ]
                        }
                    }"></FaqItem>

                </div>
            </div>
        </div>
        <div class="separator"></div>
        <div class="inline mob-col">
            <p class="subtext left-col" v-html="$t('owner_leftText_5')"></p>
            <div class="right-col">
              <div class="faq">
                  <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                      id: 0,
                      title: $t('owner_faq19_title'),
                      content: {
                          text: '<p>' + $t('owner_faq19_text') + '</p>'
                      }
                  }"></FaqItem>

                  <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                      id: 21,
                      title: $t('owner_faq20_title'),
                      content: {
                          text: '<p>' + $t('owner_faq20_text') + '</p>'
                      }
                  }"></FaqItem>

                  <FaqItem :showList="showList" @openFaq="toggleFaq" :data="{
                      id: 20,
                      title: $t('owner_faq21_title'),
                      content: {
                          text: '<p>' + $t('owner_faq21_text') + '</p>'
                      }
                  }"></FaqItem>
              </div>
            </div>
        </div>
    </div>
    <Popup @closePopup="closePopup()" :showPopup="showPopup"></Popup>
  </section>
</template>

<script>
  import Popup from './Popup.vue';
  
  import Youtube from './parts/Youtube.vue'
  import HowTo from './parts/HowTo.vue'
  import DownloadLine from './DownloadLine.vue'
  
  import FaqItem from './parts/FaqItem.vue'
  import BigStepsSlider from './parts/BigStepsSlider.vue'

  export default {
    components: {
        Youtube,
        HowTo,
        DownloadLine,
        FaqItem,
        BigStepsSlider,
        Popup
    },
    data () {
        return {
            active: 0,
            showList: [],
            showPopup: false
        }
    },
    methods: {
        toggleFaq (id) {
            let index = this.showList.indexOf(id)
            if (index != -1) {
                this.showList.splice(index, 1);
            } else {
                this.showList.push(id)
            }
        },
      openWhatsApp () {
        window.location.href = 'https://wa.me/message/FLXBNJ7HAFLCM1';
      },
      openOwnerRegForm () {
        this.showPopup = "owner_registration_form";
        if (this.$isMobile()) {
            document.body.style.overflow = 'hidden';
        }
      },
        
      closePopup () {
        this.showPopup = false;
        if (this.$isMobile()) {
            document.body.style.overflow = 'auto';
        }
      },
      // openOwnersForm(){
      //   alert(this.showPopup);
      //   openPopup2("1");
      //   // this.$router.push('/owners/form#questionary')
      // },
      created () {
          history.replaceState(null, null, ' ');
      }
    }
  }
</script>